import { graphql } from 'gatsby';
import { PageWrapper } from 'components/Layout/PageWrapper';
import { Section } from 'styles/elements';

const UpdateTemplate = ({ data, pageContext }) => {
  const { datoCmsUpdate } = data;
  const { seo, title } = datoCmsUpdate;

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.seoImage?.seoImageUrl}
    >
      <Section>
        {title}
      </Section>
    </PageWrapper>
  );
};

export default UpdateTemplate;

export const query = graphql`
    query UpdatePageQuery($locale: String!, $id: String!) {
        datoCmsUpdate(locale: { eq: $locale }, originalId: { eq: $id }) {
            locale
            seo {
                seoTitle: title
                seoDescription: description
                seoImage: image {
                    seoImageUrl: url
                }
            }
            title
        }
    }
`;
